@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.Home-hero-image-container{
    height: 100vh;
    padding: 0px;
    margin: 0px;
    background-color: rosybrown;
}
.Home-hero-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;
}

.Home-hero-text-container{
    position: absolute;
    z-index: 1;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.Home-hero-text-container .container p{
    color: #ffffff;
    font-weight: 300;
    font-size: 32px;
    font-family: 'Poppins', sans-serif;
}

.Home-hero-text-container .container a{
    background-color: #00707A;
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    padding: 10px 20px;
    border: none;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}

.Home-hero-text-container .container a:visited{
    background-color: #00707A;
    color: #ffffff;
}

.Home-our-qualities-panel{
    background-color:#DBD499;
    color: #00707A;
    position: relative;
}

.Home-our-qualities-panel p{
    font-size: 24px;
    font-style: italic;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding:30px 0px;
    margin-bottom: 0px;
    text-align: center;
}

.Home-our-qualities-panel img{
    width: 70px;
    position: absolute;
    bottom: -10px;
    right: 10%;
}

.Home-statistics-container{
    position: relative;
    background-color: rgb(221, 219, 90);  
    height: 100vh;
}

.Home-statistics-bg-image-container img{
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    z-index: 0;
}

.Home-statistics-text-container{
    display: flex;
    justify-content: space-around;
}

.Home-statistics-text-value-container{
    position: absolute;
    width: 100%;
    z-index: 1;
    margin-top: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #ffffff;
}

.Home-statistics-text-value-container h4{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.Home-statistics-glass-card-container{
    /* Color stop: A gradient going from the bottom to top,
    starting blue, turning green at 40% of its length,
    and finishing red */
    /* background-color:linear-gradient(0deg, #118C9F,#6CA2A7); */
    background-color: #118C9F;
    display: flex;
    width: 100%;
    height: 40vh;
    margin-top: 10vh;
    padding: 30px 20px;
    border-radius: 5px;
}


.Home-statistics-glass-container-first-child{
    border-right: 1px solid #207783;
}

.Home-statistics-glass-container-first-child,
.Home-statistics-glass-container-second-child {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    justify-content: center;
}

.Home-statistics-glass-container-second-child {
    
    justify-content: space-evenly;
}

.Home-statistics-glass-container-first-child label:nth-child(1){
    color: #ffffff;
    font-size: 44px;
    font-weight: 600;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}
.Home-statistics-glass-container-first-child label:nth-child(2){
    color: #ffffff;
    font-size: 28px;
    font-weight: 200;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin-top: 20px;
}
.Home-statistics-glass-container-second-child-clients{
    
    text-align: center;
}

.Home-statistics-glass-container-second-child-clients label:nth-child(1) {
    color: #ffffff;
    font-size: 44px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}
.Home-statistics-glass-container-second-child-clients label:nth-child(2){
    color: #ffffff;
    font-size: 28px;
    font-weight: 200;
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
}
.Home-statistics-glass-container-second-child-clients,
.Home-statistics-glass-container-second-child-projects{
    
    text-align: center;
}

.Home-statistics-glass-container-second-child-projects label:nth-child(1) {
    color: #ffffff;
    font-size: 44px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}
.Home-statistics-glass-container-second-child-projects label:nth-child(2){
    color: #ffffff;
    font-size: 28px;
    font-weight: 200;
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
}

.Home-statistics-glass-container-second-child hr{ 
    margin: 0px 20% ;
    opacity: 1;
    width: 60%;
    height: 1px;
    background-color: #207783;
}

.Home-statistics-container-footer-note-container{
    display: flex;
    justify-content: space-around;
}
.Home-statistics-container-footer-note-container p {
    position: absolute;
    width: 100%;
    z-index: 3;
    bottom: 0;
    text-align: center;
    color: #ffffff;
    font-size: 26px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif; 
    padding-bottom: 5vh;
}

.Home-statistics-black-overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom right, rgba(0,0,0,0) , rgba(0,0,0,0.8) );
}

.Home-bottom-container {
    padding: 30px;
    background-color: #ffffff;
}


.Home-bottom-container p{
    padding: 30px;
    text-align: center;
    font-weight: 400;
    font-size: 26px;
    font-family: 'Poppins', sans-serif; 
    color: #00707A;
}

.Home-bottom-container-video-player-container{
    width: 100%;
}

.Home-bottom-container-video-player-container div{
    width: 80%;
    margin: 0px auto;
    height: 500px;
    background-color: rgb(0 0 0);
}

.Home-bottom-container-video-player-container div {
    height: auto;
}

@media(min-width: 1400px){
    .Home-bottom-container-video-player-container div {
        height: auto;
    }
    
    .navbar-expand-lg .navbar-nav {
        padding-right: 0px;
    }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {

    .Home-bottom-container-video-player-container div {
        height: auto;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .Home-bottom-container-video-player-container div {
        height: auto;
    }
  
    .Home-hero-text-container .container p {
        padding: 0px 10%;
    }
    .Home-hero-text-container .container button {
        margin: 0px 0px 0px 10%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

    .Home-bottom-container-video-player-container div {
        height: auto;
    }
    

    .Home-hero-text-container .container p {
        padding: 0px 10%;
    }
    .Home-hero-text-container .container button {  
        margin-top: 2%;
        margin-left: 10%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {

    .Home-bottom-container-video-player-container div {
        width: 100%;
        height: auto;
    }

    .Home-bottom-container-video-player-container div {
        height: auto;
    }

    .Home-our-qualities-panel p{
        text-align: center;
        padding: 20px;
        font-size: 22px;
      }
      .Home-statistics-text-value-container h4 {
        text-align: center;
      }
  
      .Home-statistics-glass-container-first-child label:nth-child(1) ,
      .Home-statistics-glass-container-second-child-projects label:nth-child(1),
      .Home-statistics-glass-container-second-child-clients label:nth-child(1) {
          font-size: 40px;
      }
     
      .Home-statistics-glass-container-first-child label:nth-child(2) {
        margin-top: 15px;
      }
  
      .Home-statistics-glass-container-second-child-projects label:nth-child(2),
      .Home-statistics-glass-container-second-child-clients label:nth-child(2),
      .Home-statistics-glass-container-first-child label:nth-child(2) 
       {
          font-size: 22px;
        }
  
      .Home-hero-text-container .container p {
        margin: 150px 0px 0px 20px;
        font-size: 28px;
        font-weight: 400;
    }
  
      .Home-hero-text-container .container button {
        font-size: 22px;
        margin: 22px 0px 0px 25px;
    }
}

@media (max-width: 575.98px) {

    .Home-bottom-container-video-player-container div {
        height: auto;
    }

    
    .Home-our-qualities-panel p {
        font-size: 20px;
        padding: 20px;
    }

    .Home-hero-text-container .container button {
        font-weight: 400;
        font-size: 18px;
        margin-left: 20px;
    }

    .Home-hero-text-container .container p {
        font-size: 26px;
        padding-left: 20px;
        margin-top: 60px;
    }

    .Home-bottom-container p {
        padding: 5px;
        font-size: 21px;
    }

    .Home-statistics-glass-card-container {
        margin-top: 5vh;
    }

    .Home-statistics-glass-card-container {
        height: 50vh;
    }

    .Home-bottom-container-video-player-container div{
        width: 100%; 
        height: auto;
    }


    .Home-statistics-container-footer-note-container p {
        font-size: 20px;
        padding: 20px;
    }

    .Home-statistics-text-value-container h4 {
        text-align: center;
    }
    .Home-statistics-glass-container-second-child-clients, 
    .Home-statistics-glass-container-second-child-projects {
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    .Home-statistics-glass-container-first-child label:nth-child(1) ,
      .Home-statistics-glass-container-second-child-projects label:nth-child(1),
      .Home-statistics-glass-container-second-child-clients label:nth-child(1) {
          font-size: 40px;
      }

      .Home-statistics-glass-container-first-child label:nth-child(2),
      .Home-statistics-glass-container-second-child-clients label:nth-child(2),
      .Home-statistics-glass-container-second-child-projects label:nth-child(2) {
        font-size: 18px;
        margin-left: 0px;
      }
     
}