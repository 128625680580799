.AdminGallery-root-container{
    background-color: rgb(160 160 171);
    min-height: 100vh;
    padding-bottom: 100px;
    font-family: 'Poppins', sans-serif; 
}

.AdminGallery-main-content{
    padding-top: 200px;
    display: flex;
    flex-direction: column;
}

.AdminGallery-main-content form span{
    display: flex;
}

.AdminGallery-filter-section-top-and-bottom {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #061528;
    display: inline-block;
    margin-left: 30px;  
    padding: 4px 8px;
    border-radius: 5px;
    cursor: pointer;
    color: #ffffff;
}

.AdminGallery-list-of-work-dropdown-card{
    padding: 2px 4px;
    font-family: 'Poppins', sans-serif;
}

.AdminGallery-filter-section-top-and-bottom {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #061528;
    display: inline-block;
    margin-left: 30px;  
    padding: 4px 8px;
    border-radius: 5px;
    cursor: pointer;
    color: #ffffff;
}
.AdminGallery-list-of-status{
    background-color: #ffffff;
    border-radius: 5px;
    list-style: none;
    margin: 0px !important;
    display: block;
    width: 300px;
    position: absolute;
    top:40px;
    left: 0px;
    box-shadow: 2px 2px 10px #4a4a4a;
    z-index: 2;
    padding: 20px 0px;
}

.AdminGallery-list-of-status li {
    color: rgb(74, 81, 87);
    font-size: 16px !important;
    padding: 15px 4px 15px 20px;
    cursor: pointer;
    display: block
}

.AdminGallery-list-of-status li:hover {
    background-color: rgb(74, 81, 87);
    color: #ffffff;
    
}

.AdminGallery-enquiry-entry-successPopup-outer{
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AdminGallery-enquiry-entry-successPopup-inner{
    background-color: #ffffff;
    padding: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.AdminGallery-enquiry-entry-successPopup-inner div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.AdminGallery-enquiry-entry-successPopup-inner span{
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.AdminGallery-enquiry-entry-successPopup-inner button{
    top: 0px;
    right: 0px;
    border: none;
    background-color: #1094A9;
    color: #ffffff;
    padding: 1px 10px;
}

.AdminGallery-enquiry-entry-successPopup-inner img{
    height: 50px;
}

.AdminGallery-image-and-thank-you{
    display: flex;
    flex-direction: column;
}

.AdminGallery-enquiry-entry-successPopup-inner div:nth-child(2) > p {
    text-align: center;
}

.AdminGallery-previous-images ul{
    padding-left: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.AdminGallery-previous-images ul li{
    background-color: #a7a7a7;
    border: 1px solid #939191;
    margin: 10px;
}
.AdminGallery-previous-images ul li button{
    border: none;
    outline: none;
    margin-left: auto;
    display: block;
    background-color: #ff0000;
    color: #ffffff;
}

.AdminGallery-previous-images li img{
    width: 200px;
    height: 200px;
    object-fit: cover;
    border: 1px solid floralwhite;
    margin: 10px;
}


.AdminGallery-unauthorized-access-container{
    padding-top: 200px;
}