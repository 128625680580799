@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.Gallery-top-container{
    position: relative;
    height: 100vh;
}
.Gallery-image-container{
    height: 100vh;
}

.Gallery-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Gallery-statistics-black-overlay {
    position: absolute;
    z-index: 1;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to bottom right, rgba(0,0,0,0.1) , rgba(0,0,0,0.8) );
}


.Gallery-text-container{
    position: absolute;
    z-index: 1;
    top: 25vh;
    width: 100%;
    height: 100vh;
}


.Gallery-text-container-details p{
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 300;
    text-align: justify;
    text-indent: 85px;
}


.Gallery-name-panel-container{
    position: relative;
}

.Gallery-name-panel-container img{
    z-index: 2;
}

.Gallery-name-panel-container h3{
    position: absolute;
    z-index: 3;
    top:10px;
    left: 80px;
    color: #ffffff;
    text-shadow: 0 0 9px #5c5a5a;
}

.Gallery-list-container{
    background-color:#DFEBEC;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 100px;
    margin-top: 50px;
}

.Gallery-list-container-inner-content ul{
    list-style: none;
}

.Gallery-list-container-inner-content ul li{
    color: #787676;
    font-weight: 500;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    padding: 16px;
}

.Gallery-blob-container {
    position: absolute;
    right: 0px;
}


.Gallery-blob-container img:nth-child(1){
    position: absolute;
    z-index: 5;
    right: 100px;
    top: 300px;
}

.Gallery-blob-container img:nth-child(2){
    position: absolute;
    right: 120px;
    z-index: 6;
}

.Gallery-led-container{
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
}

.Gallery-blob-container img:nth-child(3){
    position: absolute;
    z-index: 7;
    top:0px;
    right: 550px;
}
.Gallery-led-container-inner-content{
    padding-bottom: 20px;
}

.Gallery-led-container-inner-content label{
    display: block;
    margin: 0px auto;
    color: #787676;
    font-weight: 600;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.Gallery-led-tagline-underline{
   background-color: #DFEBEC;
   width: 300px;
   height: 10px !important;
   margin: 0px auto;
   opacity: 1;
}

.Gallery-bottom-container-video-player-container{
    margin-top: 40px;
    width: 100%;
}

.Gallery-bottom-container-video-player-container div{
    width: 80%;
    margin: 0px auto;
    height: 500px;
    background-color: rgb(0 0 0);
}
.Gallery-led-container h4{
    color: #787676;
    font-weight: 600;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    margin-top: 60px;
}

.Gallery-brand-name-container {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-around;
    padding-top: 60px;
}

.Gallery-image-of-gallery{
    width: 32%;
    height: 200px;
    object-fit: cover;
    border: 5px solid #afa974;
    cursor: pointer;
}


.Gallery-image-list-wrapper{
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 2%;
}


.Gallery-outer-shadow-container{
    background-color: rgba(0, 0, 0, 0.90);
    position: fixed;
    z-index: 5;
    top:0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}


.Gallery-outer-shadow-container span{
    background-color: #ffffff;
    border: 2px solid #7C731C;
    color: #7C731C;
    font-size: 24px;
    display: inline-block;
    margin: 40px auto 10px auto;
    font-size: 18px;
    padding: 10px 30px;
    cursor: pointer;
}


.Gallery-outer-shadow-container img{
    display: block;
    width: 80%;
    height: 80%;
    object-fit: contain;
    margin: 20px auto 10px auto;
}
.Gallery-list-container-inner-content > label{
    font-family: 'Poppins', sans-serif;
    color: #4a4a4a;

}
.Gallery-list-of-work-dropdown-card{
    padding: 2px 4px;
    font-family: 'Poppins', sans-serif;
}
.Gallery-filter-section-top-and-bottom {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #061528;
    display: inline-block;
    margin-left: 30px;  
    padding: 4px 8px;
    border-radius: 5px;
    cursor: pointer;
    color: #ffffff;
}

.Gallery-list-of-status{
    background-color: #ffffff;
    border-radius: 5px;
    list-style: none;
    margin: 0px !important;
    display: block;
    width: 300px;
    position: absolute;
    top:40px;
    left: 0px;
    box-shadow: 2px 2px 10px #4a4a4a;
    z-index: 2;
    padding: 20px 0px;
}

.Gallery-list-of-status li {
    color: rgb(74, 81, 87);
    font-size: 16px !important;
    padding: 15px 4px;
    cursor: pointer;
    display: block
}

.Gallery-list-of-status li:hover {
    background-color: rgb(74, 81, 87);
    color: #ffffff;
    
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .Gallery-image-of-gallery {
        width: 46%;
        height: 133px;
        object-fit: cover;
        border: 5px solid #afa974;
    }
}

@media (max-width: 575.98px){
    .Gallery-image-of-gallery {
        width: 46%;
        height: 133px;
        object-fit: cover;
        border: 5px solid #afa974;
    }
}