.Contact-top-container{
    position: relative;
    height: 100vh;
}
.Contact-image-container{
    height: 100vh;
}

.Contact-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Contact-statistics-black-overlay {
    position: absolute;
    z-index: 1;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to bottom right, rgba(0,0,0,0.1) , rgba(0,0,0,0.8) );
}


.Contact-text-container{
    position: absolute;
    z-index: 1;
    top: 25vh;
    width: 100%;
    height: 100vh;
}

.Contact-name-panel-container{
    position: relative;
}

.Contact-name-panel-container img{
    z-index: 2;
}

.Contact-name-panel-container h3{
    position: absolute;
    z-index: 3;
    top:10px;
    left: 80px;
    color: #ffffff;
    text-shadow: 0 0 9px #5c5a5a;
}

.Contact-address-container{
    background-image: url("../img/ic_repeat_bg.png");
    padding: 60px 0px 100px 0px;
}
.Contact-address-container-head-label{
    font-weight: 600;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    color: #000000;
}
.Contact-address-card{
    background-color: #0B5F66;
    margin-top: 30px;
    padding: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Contact-address-card-first-child {
    padding: 2px;
    display: flex;
    justify-content: space-around;
    width: 50%;
}

.Contact-address-card p{
    color: #ffffff;
    padding: 2px;
    font-style: italic;
    font-weight: 600;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
}

.Contact-typing-box-fields-container{
    padding: 60px 0px 100px 0px;
}
.Contact-typing-box-fields-container h3{
    
        font-weight: 600;
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        color: #000000;
}

.Contact-typing-box-fields-container p{
    
    font-weight: 400;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    color: #45AD49;
}

.Contact-typing-box-fields-container-top-panel{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
}

.Contact-typing-box-fields-container-top-panel span{
    display: flex;
    flex-direction: column;
    width: 30%;
    font-size: 18px;
    color: #767676;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.Contact-typing-box-fields-container-top-panel span input,
.Contact-typing-box-fields-container-bottom-panel span textarea{
    margin-top: 10px;
    border: 1px solid #1094A9;
}

.Contact-typing-box-fields-container-bottom-panel span{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: #767676;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-top: 20px;
}

.mandatory-input-symbol{
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: #ff0000;
}


.Contact-typing-box-full-length-label {
    display: flex;
}

.Contact-typing-box-fields-send-button-container{
    padding-top: 20px;
}
.Contact-typing-box-fields-container  button{
    display: block;
    background-color: #00707A;
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    padding: 10px 20px;
    border: none;
    font-family: 'Poppins', sans-serif;
    margin-left: auto;
    margin-right: auto;
}

.Contact-form-error-message {
    color: #ff0000 !important;
}

.Contact-enquiry-entry-successPopup-outer{
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Contact-enquiry-entry-successPopup-inner{
    background-color: #ffffff;
    padding: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.Contact-enquiry-entry-successPopup-inner div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Contact-enquiry-entry-successPopup-inner span{
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.Contact-enquiry-entry-successPopup-inner button{
    top: 0px;
    right: 0px;
    border: none;
    background-color: #1094A9;
    color: #ffffff;
    padding: 1px 10px;
}

.Contact-enquiry-entry-successPopup-inner img{
    height: 50px;
}

.Contact-image-and-thank-you{
    display: flex;
    flex-direction: column;
}

.Contact-enquiry-entry-successPopup-inner div:nth-child(2) > p {
    text-align: center;
}
@media (min-width: 768px) and (max-width: 991.98px){
    .Contact-address-card{
        display: flex;
        flex-direction: column;
        padding: 20px 0px;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .Contact-address-card{
        display: flex;
        flex-direction: column;
    }
    .Contact-address-card-first-child{
        width: auto;
    }
    .Contact-address-card iframe{
        border: 0px;
        width: 100%;
    }
    .Contact-address-card p {
            font-size: 16px; 
            padding-top: 16px;
            padding-bottom: 16px;
    }
}

@media (max-width: 575.98px){
    .Contact-address-card{
        display: flex;
        flex-direction: column;
    }

    .Contact-address-card-first-child{
        width: auto;
    }
    .Contact-address-card p {
       font-size: 16px; 
       padding-top: 16px;
       padding-bottom: 16px;
    }
    
    .Contact-address-card iframe{
    border: 0px;
    width: 100%;
    }

    .Contact-typing-box-fields-container-top-panel span {
        display: flex;
        flex-direction: column;
        width: auto; 
    }

    .Contact-typing-box-fields-container-top-panel {
        display: flex;
        flex-direction: column;
    }
}