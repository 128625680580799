.AdminLogin-root-container{
    background-color: #656a83;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AdminLogin-form-container{
    background-color: seagreen;
}

.AdminLogin-form{
    background-color: white;
    width: 25vw;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
}

.AdminLogin-form label,
.AdminLogin-form input{
    font-family: 'Poppins', sans-serif;
}
.AdminLogin-form label{
    color: #585757;
}
.AdminLogin-form input{
    padding: 10px;
}

.AdminLogin-form button{
    background-color: #00707A;
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    padding: 10px 20px;
    border: none;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}

.AdminLoginform-input-error {
    color: red;
}