@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap');


.AdminHome-root-container{
    background-color: rgb(160 160 171);
    min-height: 100vh;
    padding-bottom: 100px;
    font-family: 'Poppins', sans-serif; 
}

.AdminHome-main-content{
    padding-top: 200px;
}

.AdminHome-enquiry-list-container ul{
    padding-left: 0px;
    margin-top: 20px;
}
.AdminHome-enquiry-list-container li {
    list-style: none;
    background-color: #ffffff;
}

.AdminHome-card-container {
    padding: 18px 35px;
}

.AdminHome-enquiry-top-panel{
    display: flex;
    justify-content: space-between;
}

.AdminHome-enquiry-top-panel > div,
.AdminHome-enquiry-bottom-panel > div{
    display: flex;
    flex-direction: column;
}


.AdminHome-enquiry-top-panel > div > label:nth-child(1),
.AdminHome-enquiry-bottom-panel > div > label:nth-child(1){
    font-size: 14px;
    color: #767676;
}


.AdminHome-enquiry-top-panel > div > label:nth-child(2),
.AdminHome-enquiry-bottom-panel > div > label:nth-child(2){
    font-size: 16px;
    color: #000000;
}

.AdminHome-head-panel-heading-and-link{
    display: flex;
    justify-content: space-between;
}

.AdminHome-logout-button{
    border: none;
    font-size: 14px;
    padding: 4px 20px;
}

.AdminHome-head-panel-heading-and-link a{
    background-color: #00707A;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    text-decoration: none;
}
.LogoutPopup-root-container{
    background-color: #00000080;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100vh;
    z-index: 4;
}

.LogoutPopup-root-container-child{
    width: 30%;
    margin: 50px auto 0px auto;
    padding: 20px;
    text-align: center;
}