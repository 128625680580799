@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.Footer-root-container{
    display: flex;
    justify-content: space-around;
    background-color: rgba(11, 97, 102, 0.9);
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    padding: 30px 24px;
}

.Footer-text-container {
    display: flex;
}

.Footer-about-section{
    width: 35%;
    padding-right: 50px;
}

.Footer-quick-links{
    width: 15%;
}
.Footer-quick-links ul,
.Footer-contact-section ul{
    list-style: none;
    padding-left: 0px;
}


.Footer-quick-links ul li a{
    text-decoration: none;
    color: #ffffff;
}


.Footer-quick-links ul li a:visited{
    color: #ffffff;
}



.Footer-quick-links ul li,
.Footer-contact-section ul li,
.Footer-about-section p,
.Footer-location-section p{
    font-weight: 300;
}


.Footer-about-section h5,
.Footer-quick-links h5,
.Footer-contact-section h5,
.Footer-location-section h5
{
    padding-bottom: 20px;
}


.Footer-contact-section{
    width: 20%;
}

.Footer-location-section{
    width: 30%;
}

@media(min-width:1400px){
    .Footer-quick-links{
        text-align: center;
    }
    .Footer-text-container {
        display: flex;
        justify-content: space-between;
    }
}

@media(min-width:1200px) and (max-width:1399.98px){

    .Footer-quick-links{
        text-align: center;
    }
    .Footer-text-container {
        display: flex;
        justify-content: space-between;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .Footer-about-section,
    .Footer-quick-links,
    .Footer-contact-section,
    .Footer-location-section{
        width: 100%;
    }

    .Footer-text-container {
        display: flex;
        flex-direction: row;
    }

    .Footer-about-section {
        padding-right: 0px;
    }
    .Footer-quick-links{
        text-align: center;
    }
    .Footer-text-container {
        display: flex;
        justify-content: space-between;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .Footer-about-section,
    .Footer-quick-links,
    .Footer-contact-section,
    .Footer-location-section{
        width: 100%;
    }

    .Footer-text-container {
        display: flex;
        flex-direction: column;
    }

    .Footer-about-section {
        padding-right: 0px;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .Footer-about-section,
    .Footer-quick-links,
    .Footer-contact-section,
    .Footer-location-section{
        width: 100%;
    }

    .Footer-text-container {
        display: flex;
        flex-direction: column;
    }

    .Footer-about-section {
        padding-right: 0px;
    }
}

@media (max-width: 575.98px) {
.Footer-about-section,
    .Footer-quick-links,
    .Footer-contact-section,
    .Footer-location-section{
        width: 100%;
    }

    .Footer-text-container {
        display: flex;
        flex-direction: column;
    }

    .Footer-about-section {
        padding-right: 0px;
    }
}