
.AboutUs-top-container{
    position: relative;
    height: 100vh;
}
.AboutUs-image-container{
    height: 100vh;
}

.AboutUs-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.AboutUs-text-container{
    position: absolute;
    z-index: 1;
    top: 36vh;
    width: 100%;
    height: 246px;
    overflow-y: scroll;
}

.AboutUs-text-container-details p{
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 300;
    text-align: justify;
    text-indent: 85px;
}

.AboutUs-name-panel-container{
    position: relative;
}

.AboutUs-name-panel-container img{
    z-index: 2;
}

.AboutUs-name-panel-container h3{
    position: absolute;
    z-index: 3;
    top:10px;
    left: 80px;
    color: #ffffff;
    text-shadow: 0 0 9px #5c5a5a;
}

.AboutUs-statistics-black-overlay {
    position: absolute;
    z-index: 1;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to bottom right, rgba(0,0,0,0.1) , rgba(0,0,0,0.8) );
}

.AboutUs-our-work-process-container {
    background-color: #FFFBDC;
    padding-bottom: 50px;
}

.AboutUs-our-work-process-details{
    display: flex;
    justify-content: space-around;
}

.AboutUs-our-work-process-details .container{
    position: relative;
}

.AboutUs-our-work-process-details .container h4{
    color: #132829;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-top: 50px;
}

.AboutUs-our-work-process-round-image {
    width: 250px;
}


.AboutUs-work-process-first-panel ,
.AboutUs-work-process-second-panel,
.AboutUs-work-process-third-panel{
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}

.AboutUs-work-process-first-panel p,
.AboutUs-work-process-second-panel p,
.AboutUs-work-process-third-panel p{
    text-align: center;
    color: #AD9C45;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.AboutUs-work-process-first-panel div,
.AboutUs-work-process-second-panel div {
    align-items: center;
}

.AboutUs-first-line-work-process img{
    position: absolute;
    height: 200px;
    object-fit: cover;
    top: 300px;
    left: 39%;
}

.AboutUs-second-line-work-process img{
    position: absolute;
    height: 140px;
    object-fit: cover;
    top: 620px;
    left: 48%;
}

.AboutUs-work-process-third-panel{
    margin-top: 70px;
}


.AboutUs-work-process-second-panel{
    margin-top: 70px;
}

.AboutUs-image-gallery-container{
    background-color: #FFFBDC;
    padding-top: 80px;
}

.AboutUs-third-container,
.AboutUs-first-container{
    display: flex;
    justify-content: center;
    margin-top: -48px;
}

.AboutUs-third-container img,
.AboutUs-first-container img{
    width: 300px;
}

.AboutUs-first-container img:nth-child(2){
    margin-left: -48px;
    margin-top: 20px;
}

.AboutUs-image-gallery-container .container p{
    text-align: center;
    color: #AD9C45;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    padding: 20px 0px 50px 0px;
}



@media(min-width: 1400px){
    .AboutUs-work-flexible-arrow-container-1 img{
        width: 91px;
    height: 170px;
    margin-left: 50%;
    object-fit: cover;
   }
   .AboutUs-work-flexible-arrow-container-2 img{
    width: 37px;
    height: 141px;
    margin-left: 30%;
    object-fit: cover;
    }
    .AboutUs-work-process-third-panel {
        margin-top: 70px;
    }
    .AboutUs-work-process-second-panel {
        margin-top: 60px;
    }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
    .AboutUs-work-flexible-arrow-container-1 img{
        width: 91px;
    height: 170px;
    margin-left: 50%;
    object-fit: cover;
   }
   .AboutUs-work-flexible-arrow-container-2 img{
    width: 37px;
    height: 141px;
    margin-left: 30%;
    object-fit: cover;
    }
    .AboutUs-work-process-third-panel {
        margin-top: 40px;
    }
    .AboutUs-work-process-second-panel {
        margin-top: 30px;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .AboutUs-work-flexible-arrow-container-1 img{
        width: 52px;
        height: 100px;
        margin-left: 50%;
        object-fit: cover;
   }
   .AboutUs-work-flexible-arrow-container-2 img{
        width: 28px;
        height: 100px;
        margin-left: 30%;
        object-fit: cover;
    }
    .AboutUs-work-process-third-panel {
        margin-top: 40px;
    }
    .AboutUs-work-process-second-panel {
        margin-top: 30px;
    }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .AboutUs-work-process-third-panel div{
        text-align: center;
    }

    .AboutUs-second-line-work-process img {
        height: 80px;
        top: 441px;
    }
    .AboutUs-first-line-work-process img {
        height: 100px;
        top: 230px;
    }

    .AboutUs-third-container img, .AboutUs-first-container img {
        width: 170px;
    }

    .AboutUs-our-work-process-round-image {
        width: 130px;
    }

    .AboutUs-name-panel-container h3 {
        font-size: 24px;
    }

    .AboutUs-name-panel-container img {
        height: 60px;
    }

    .AboutUs-text-container-details p {
        padding: 0px 30px;
        font-size: 20px;
    }
    .AboutUs-top-container {
        height: 750px;
    }
    .AboutUs-image-container {
        height: 750px;
    }
    .AboutUs-statistics-black-overlay {
        height: 750px;
    }
    .AboutUs-text-container {
        top: 150px;
        height: 560px;
        overflow-y: scroll;
    }

    .AboutUs-work-flexible-arrow-container-1 img{
        width: 52px;
        height: 100px;
        margin-left: 50%;
        object-fit: cover;
   }
   .AboutUs-work-flexible-arrow-container-2 img{
        width: 28px;
        height: 100px;
        margin-left: 30%;
        object-fit: cover;
    }
    .AboutUs-work-process-third-panel {
        margin-top: 40px;
    }
    .AboutUs-work-process-second-panel {
        margin-top: 30px;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) {

    .AboutUs-work-process-third-panel div{
        text-align: center;
    }

    .AboutUs-second-line-work-process img {
        height: 65px;
        top: 441px;
    }
    .AboutUs-first-line-work-process img {
        height: 75px;
        top: 214px;
    }

    .AboutUs-third-container img, .AboutUs-first-container img {
        width: 170px;
    }

    .AboutUs-our-work-process-round-image {
        width: 100px;
    }

    .AboutUs-name-panel-container h3 {
        font-size: 24px;
    }

    .AboutUs-name-panel-container img {
        height: 60px;
    }

    .AboutUs-text-container-details p {
        padding: 0px 30px;
        font-size: 20px;
    }
    .AboutUs-top-container {
        height: 850px;
    }
    .AboutUs-image-container {
        height: 850px;
    }
    .AboutUs-statistics-black-overlay {
        height: 850px;
    }
    .AboutUs-text-container {
        top: 120px;
        height: 680px;
        overflow-y: scroll;
    }

    .AboutUs-work-flexible-arrow-container-1 img{
        width: 52px;
        height: 100px;
        margin-left: 50%;
        object-fit: cover;
   }
   .AboutUs-work-flexible-arrow-container-2 img{
        width: 28px;
        height: 100px;
        margin-left: 30%;
        object-fit: cover;
    }
    .AboutUs-work-process-third-panel {
        margin-top: 40px;
    }
    .AboutUs-work-process-second-panel {
        margin-top: 30px;
    }

}

@media (max-width: 575.98px) {

    .AboutUs-work-process-third-panel {
        margin-top: 40px;
    }
    .AboutUs-work-process-second-panel {
        margin-top: 30px;
    }

    .AboutUs-our-work-process-details .container h4 {
        text-align: center;
    }
    
    .AboutUs-name-panel-container h3 {
        font-size: 18px;
    }

    .AboutUs-name-panel-container img {
        height: 50px;
    }

    .AboutUs-work-process-first-panel p,
.AboutUs-work-process-second-panel p,
.AboutUs-work-process-third-panel p{
    font-size: 16px;
}

    .AboutUs-work-process-third-panel div {
        text-align: center;
    }

    .AboutUs-text-container-details p {
        padding: 0px 30px;
        font-size: 16px;
    }
    .AboutUs-top-container {
        height: 800px;
    }
    .AboutUs-image-container {
        height: 800px;
    }
    .AboutUs-statistics-black-overlay {
        height: 800px;
    }
    .AboutUs-text-container {
        top: 100px;
        height: 680px;
        overflow-y: scroll;
    }


    .AboutUs-third-container img, .AboutUs-first-container img {
        width: 170px;
    }

    .AboutUs-our-work-process-round-image {
        width: 80px;
        margin: 10px !important;
    }

   .AboutUs-work-flexible-arrow-container-1 img{
        width: 52px;
        height: 100px;
        margin-left: 50%;
        object-fit: cover;
   }
   .AboutUs-work-flexible-arrow-container-2 img{
        width: 28px;
        height: 100px;
        margin-left: 30%;
        object-fit: cover;
    }
}