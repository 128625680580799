
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.Header-root-container{
    width: 100%;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
}
.header_company_logo{
    height: 80px;
    background-color: #ffffff;
    padding: 2px;
    border-radius: 5px;
    box-shadow: 1px 1px black;
  }
  .Header-top-phone-number-container img {
    height: 22px;
    margin: 0px 15px 0px 50px;
  }
  
  .Header-top-phone-number-container-divider-slash {
    margin: 0px 10px;
  }
  
  .header-right-section {
    margin-left: auto;
  }
  .header-top-bar {
    margin: 0px auto !important;
  }
  
  .Header-nav-container .nav-link {
    color: rgb(255, 255, 255) !important;
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
  }
  
  .Header-nav-container .active {
    color: #d6c94b !important;
    border-bottom: 5px solid #d6c94b;
  }
  
  .Header-company-name{
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
  }
  .Navbar-right-contact-and-menu-container{
    display: flex;
    flex-direction: column !important;
  }
  .Header-phone-email-container {
    display: flex;
    justify-content: normal;
    margin-left: auto;
  }

  .Header-phone-email-container div{
    display: flex;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    align-items: center;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-direction: column;
}
  
  @media (min-width: 1400px){
    .Header-phone-email-container img{
      width: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .Header-phone-email-container img{
      width: 25px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .Header-phone-email-container img{
      width: 25px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .navbar-expand-lg .navbar-collapse {
      margin-top: 45px;
   }
    .Header-root-container {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: black;
    }
    .navbar-light .navbar-toggler-icon {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAAhElEQVR4nO3aMQ6AMAwEwZj//9l8AEHlHIpm6hRWtr21AAAAAAAAADhVfT3o7t5xyKmq6vWPr12H8EyAMAHCBAgTIEyAMAEAAAAAAACG2QUNswv6OQHCBAgTIEyAMAHCBAAAAAAAABhmFzTMLujnBAgTIEyAMAHCBAgTAAAAAAAAAACG3E2GDC7VbgLhAAAAAElFTkSuQmCC);
    }
    .Header-phone-email-container img{
      width: 25px;
    }
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {

    .Header-root-container {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: black;
    }

    .navbar-expand-lg .navbar-collapse {
      margin-top: 45px;
   }

    .navbar {
      padding-top: 0px;
      background-color: black;
    }

    .navbar-brand {
      display: flex;
      align-items: center;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAAhElEQVR4nO3aMQ6AMAwEwZj//9l8AEHlHIpm6hRWtr21AAAAAAAAADhVfT3o7t5xyKmq6vWPr12H8EyAMAHCBAgTIEyAMAEAAAAAAACG2QUNswv6OQHCBAgTIEyAMAHCBAAAAAAAABhmFzTMLujnBAgTIEyAMAHCBAgTAAAAAAAAAACG3E2GDC7VbgLhAAAAAElFTkSuQmCC);
  }
  .Header-phone-email-container img{
    width: 25px;
  }
  }
  
  @media (max-width: 575.98px) {

    .Header-nav-container{
      margin-top: 20px;
    }

    .Header-nav-container .nav-link {
      font-weight: 400;
      font-size: 16px;
  }

    .Header-phone-email-container div:nth-child(2) {
      margin-top: 20px;
      margin-left: 0px !important;
  }
    
    .Header-phone-email-container {
        font-size: 15px;
        flex-direction: column;
    }
    .Header-phone-email-container img{
      width: 25px;
    }
    .Header-company-name{
      font-weight: 500;
      font-size: 16px;
      margin-left: 10px !important;
    }
    .header_company_logo {
      height: 50px;
    }

    .navbar-expand-lg .navbar-collapse {
      margin-top: 30px;
   }
    .Header-root-container {
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: black;
    }

    .navbar-light .navbar-toggler-icon {
      background-image: url("../img/ic_menu_header.png");
    }
  }