@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.Services-top-container{
    position: relative;
    height: 100vh;
}
.Services-image-container{
    height: 100vh;
}

.Services-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Services-statistics-black-overlay {
    position: absolute;
    z-index: 1;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to bottom right, rgba(0,0,0,0.1) , rgba(0,0,0,0.8) );
}


.Services-text-container{
    position: absolute;
    z-index: 1;
    top: 25vh;
    width: 100%;
    height: 100vh;
}


.Services-text-container-details p{
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 300;
    text-align: justify;
    text-indent: 85px;
}


.Services-name-panel-container{
    position: relative;
}

.Services-name-panel-container img{
    z-index: 2;
}

.Services-name-panel-container h3{
    position: absolute;
    z-index: 3;
    top:10px;
    left: 80px;
    color: #ffffff;
    text-shadow: 0 0 9px #5c5a5a;
}

.Services-list-container{
    background-color:#DFEBEC;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 100px;
    margin-top: 50px;
}

.Services-list-container-inner-content ul{
    list-style: none;
}

.Services-list-container-inner-content ul li{
    color: #787676;
    font-weight: 500;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    margin-top: 30px;
}

.Services-blob-container {
    position: absolute;
    right: 0px;
}


.Services-blob-container img:nth-child(1){
    position: absolute;
    z-index: 5;
    right: 100px;
    top: 300px;
}

.Services-blob-container img:nth-child(2){
    position: absolute;
    right: 120px;
    z-index: 6;
}

.Services-led-container{
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
}

.Services-blob-container img:nth-child(3){
    position: absolute;
    z-index: 7;
    top:0px;
    right: 550px;
}
.Services-led-container-inner-content{
    padding-bottom: 20px;
}

.Services-led-container-inner-content label{
    display: block;
    margin: 0px auto;
    color: #787676;
    font-weight: 600;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.Services-led-tagline-underline{
   background-color: #DFEBEC;
   width: 300px;
   height: 10px !important;
   margin: 0px auto;
   opacity: 1;
}

.Services-bottom-container-video-player-container{
    margin-top: 40px;
    width: 100%;
}

.Services-bottom-container-video-player-container div{
    width: 80%;
    margin: 0px auto;
    height: 500px;
    background-color: rgb(0 0 0);
}
.Services-led-container h4{
    color: #787676;
    font-weight: 600;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    margin-top: 60px;
}

.Services-brand-name-container {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-around;
    padding-top: 60px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .Services-blob-container img:nth-child(1) {
        position: absolute;
        z-index: 5;
        right: 69px;
        top: 193px;
        height: 202px;
    }
    .Services-blob-container img:nth-child(2) {
        position: absolute;
        right: 120px;
        z-index: 6;
        height: 395px;
    }
    .Services-blob-container img:nth-child(3) {
        position: absolute;
        z-index: 7;
        top: 0px;
        right: 404px;
        height: 147px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .Services-blob-container img:nth-child(1) {
        position: absolute;
        z-index: 5;
        right: 86px;
        top: 101px;
        height: 212px;
    }

    .Services-blob-container img:nth-child(2) {
        position: absolute;
        right: 72px;
        height: 245px;
        z-index: 6;
    }

    .Services-blob-container img:nth-child(3) {
        position: absolute;
        z-index: 5;
        top: 0px;
        height: 100px;
        right: 40px;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .Services-blob-container img:nth-child(1) {
        position: absolute;
        z-index: 5;
        right: 86px;
        top: 101px;
        height: 120px;
    }

    .Services-blob-container img:nth-child(2) {
        position: absolute;
        right: 72px;
        height: 197px;
        z-index: 6;
    }

    .Services-blob-container img:nth-child(3) {
        position: absolute;
        z-index: 5;
        top: 0px;
        height: 100px;
        right: 40px;
    }
}
@media (max-width: 575.98px) {
    .Services-name-panel-container img {
        height: 50px;
    }
    .Services-name-panel-container h3 {
        font-size: 18px;
    }
    .Services-list-container {
        margin-top: 30px;
        height: 700px;
        padding-top: 0px;
        position: relative;
    }
   
    .Services-list-container-inner-content ul li {
        font-size: 16px;
    }
    .Services-blob-container img:nth-child(1) {
        top: 23px;
        height: 159px;
        right: 0px;
    
    }
    .Services-blob-container img:nth-child(2) {
        position: absolute;
        right: 19px;
        z-index: 6;
        height: 160px;
    }
    .Services-blob-container img:nth-child(3) {
        right: 82px;
        top: 33px;
        height: 102px;
        z-index: 5;
    }
   
    .Services-brand-name-container img{
        height: 30px;
    }

    .Services-list-container-inner-content {
        position: absolute;
        z-index: 7;
        background-color: #f0f8ffb8;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .Services-blob-container {
        top: 10px;
    }
    .Services-led-container-inner-content label {
        font-size: 18px;
    }
}
